@use '@/styles/utils/mixins.scss' as *;

.leadershipWrapper {
  display: flex;

  .leadershipList {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    width: 100%;
  }
  .leadershipItem {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }
  .membersList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
    width: 100%;
    padding-bottom: 0.125rem;

    @include tab() {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }

    .memberCard {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      align-items: center;
      padding: 1.875rem;
      border-radius: 1.25rem;
      text-align: center;
      background-color: $white;
      border: 0 solid $white;
      -webkit-box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
      -moz-box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
      box-shadow: inset 0.063rem 0px 0px 0px $primaryAlt;
      transition: all 0.2s ease-in-out;

      .memberImage {
        border-radius: 100%;
        object-fit: cover;
        height: 6.25rem;
        width: 6.25rem;
        background-color: $neutral05;
      }
      .memberDetails {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        align-items: center;
        text-align: center;
      }
    }
  }
}
